
@import "~bootstrap/scss/functions";

@import "./variables";
@import "./fonts";

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// areas definitions are declared here 'cause they bootstrap variables and functions
$area-padding-x: map-get($spacers, 3);
$area-padding-y: map-get($spacers, 2);
$area-padding-x-tabs-design: map-get($spacers, 2);
$area-padding-y-tabs-design: $area-padding-y;
$area-condensed-padding-x: $area-padding-x-tabs-design;
$area-condensed-padding-y: $area-padding-y-tabs-design;
$area-condensed-padding-x-tabs-design: map-get($spacers, 1);
$area-condensed-padding-y-tabs-design: $area-condensed-padding-x-tabs-design;

// used for app booting progress
@mixin progress($nth-child) {
    .boot-preloader .progress-part:nth-child(#{$nth-child}){
        background: $secondary;
    }
}
