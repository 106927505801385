// Utilities


/* display breakpoints, going down
=================================== */

// on extra small screens (lesser than sm)
@media (max-width: $bs-xs-max){
    .hide-lt-sm { display: none; }
}
// on screens lesser than md
@media (max-width: $bs-sm-max){
    .hide-lt-md { display: none; }
}
// on screens lesser than lg
@media (max-width: $bs-md-max){
    .hide-lt-lg { display: none; }
}
// on screens lesser than xl
@media (max-width: $bs-lg-max){
    .hide-lt-xl { display: none; }
}



/* Vue transitions
=================================== */
.component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to {
    opacity: 0;
}


/*
=================================== */

.bg-alt-plus  { background: $bg-alt-plus !important; }
.bg-alt       { background: $bg-alt       !important; }

// centers the content of selected element,
// both horizontally and vertically
.center-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

// signals that something happens when the element is clicked
.clickable {
    cursor: pointer;
}
.triggers-help {
    cursor: help;
}

.nowrap {
    white-space: nowrap;
}

// fives to the element a width suitable for a "YYYY-MM-DD" date
.date-wide {
  width: 6.5em !important;
  text-align: center;
}

.greek {
  // font-family: "Roboto Condensed", #{$font-family-sans-serif};
}

// used for dev, emphasizes what end users shouldn't see
.tmp   { background: yellow !important; }
.admin { background: orange !important; }
.dev   { background: rgb(255, 0, 200, 0.5) !important; }
