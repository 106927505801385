
/* Down breakpoints, home made,
 * based using boostrap breakpoints
 * (min supported width in the app: 360px)
===================================
 * Bootstrap breakpoints :
    -   sm: @media (min-width: 576px) { ... }
    -   md: @media (min-width: 768px) { ... }
    -   lg: @media (min-width: 992px) { ... }
    -   xl: @media (min-width: 1200px) { ... }
 */
$browser-borders: 0px; // 40px?
$bs-sm-min: 480px;                     // bs:576px;
$bs-md-min: 800px - $browser-borders;  // bs:768px
$bs-lg-min: 1024px - $browser-borders; // bs:992px;
$bs-xl-min: 1600px - $browser-borders; // bs:1200px;
$bs-xs-max: $bs-sm-min - 0.2px;
$bs-sm-max: $bs-md-min - 0.2px;
$bs-md-max: $bs-lg-min - 0.2px;
$bs-lg-max: $bs-xl-min - 0.2px;

$grid-breakpoints: (
  xs: 0,
  sm: $bs-sm-min,
  md: $bs-md-min,
  lg: $bs-lg-min,
  xl: $bs-xl-min
);

$container-max-widths: (
  sm: $bs-sm-min - 32px, // bs sm:540px,
  md: $bs-lg-min - 32px - 1px, // bs md:720px,
  lg: $bs-lg-min - 32px, // bs lg:960px,
  xl: 1300px // bs xl:1140px
);






/* Colors
=================================== */

$min-contrast-ratio: 1.5; // forces white text on most buttons (but not btn-light) // https://stackoverflow.com/a/67958494/4438482

$cyan: #17a2b8;
$light: lighten(#eae9d8, 10%);
$color-primary: #7c950f;
$color-secondary: desaturate(lighten($color-primary, 25%), 36%);

// by function
$bg-main: #f6e9c1;
$bg-alt: lighten($bg-main, 5%);
$bg-alt-plus: lighten($bg-main, 10%);

// step color codes
$step-color: $cyan;
$step-color-hop: #888;
$step-color-highlighted: #E143A9;
$step-color-selected: $color-secondary;
$step-color-loading: #F78511;
$step-color-failed: #DC3545;

/* z-indexes
=================================== */
/* boostrap z-indexes reminder:
$zindex-dropdown:          1000 !default;
$zindex-sticky:            1020 !default;
$zindex-fixed:             1030 !default;
$zindex-modal-backdrop:    1040 !default;
$zindex-modal:             1050 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
*/

/* leaflet z-indexes reminder:
.leaflet-top, .leaflet-bottom { z-index: 1000; }
.leaflet-control        { z-index: 800; }
.leaflet-popup-pane     { z-index: 700; }
.leaflet-tooltip-pane   { z-index: 650; }
.leaflet-marker-pane    { z-index: 600; }
.leaflet-shadow-pane    { z-index: 500; }
.leaflet-overlay-pane   { z-index: 400; }
.leaflet-pane           { z-index: 400; }
.leaflet-tile-pane      { z-index: 200; }
*/
$z-index-leaflet:         900; // (replaces .leaflet-top, .leaflet-bottom)
/* Doesn't seem to work...
$z-index-leaflet-control: 700;
$z-index-popup-pane:      800;
*/
$z-index-date-picker:   990;
$z-index-notices:      1060; // 1020;
$z-index-overlays:     1055;
$z-index-page-loader:  1080;
$z-index-over-leaflet: 1010;
$z-index-main-menu:    2000;



/* target screens by display style
=================================== */

$split-design-min-width: $bs-lg-min;
$tabs-design-max-width: $split-design-min-width - 0.2px;
$query-tabs-design-width:  "(max-width: " + $tabs-design-max-width + ")"; // small screens
$query-split-design-width: "(min-width: " + $split-design-min-width + ")"; // medium screens


/**
 * Bootstrap overrides
=================================== */

// Variables that override Bootstrap's are declared in ~sass/boot.scss


/* Bootstrap overrides
=================================== */

// unchanged from original Bootstrap, but needed right now
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$zindex-dropdown: $z-index-over-leaflet;
$body-bg:   $bg-main;
$primary:   $color-primary;
$secondary: $color-secondary;
$success:   $secondary;
$danger:    #E1302D;

$warning:   #F78511;
$dropdown-header-color: $primary;
$list-group-hover-bg: $secondary;
$input-group-addon-bg: $bg-alt; //$secondary; //$bg-alt
$input-group-addon-border-color: darken($secondary, 10%); //$primary
// $input-border-width: 0;


$font-family-sans-serif: "Roboto Condensed", #{$font-family-sans-serif};
$headings-color: darken($primary, 5%);

$btn-close-width: .75em;