$modal-spacer: map-get($spacers, 3);

.overlay {
    position: fixed;
    z-index: $z-index-overlays;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    // overflow: auto;
    // overflow-y: auto;
    // outline: 0;
    // margin: 0;

    $grey-level: 0;
    background: rgba($grey-level, $grey-level, $grey-level, 0.8);
    padding: $modal-spacer;

    .dialog {
        position: relative; // allows absolute position of close button
        max-height: 100%;
        max-width: map-get($container-max-widths, xl);
        overflow-y: auto;

        background: white;
        padding: $modal-spacer;
        border-radius: $border-radius; //.25rem;
    }
    
    &.full-size-sm {
        @media (max-width: $bs-sm-max){
            padding: 0;

            .dialog {
                width: 100%;
                height: 100%;
                border-radius: 0;
            }
        }
    }
}

.overlay-nav {
    text-align: right;

    .btn {
        margin-left: 0.25rem;
    }
}
