
@import "./boot";

@import "./utilities";
@import "./containers";
@import "./overlay";
@import "./debug";

// increase app booting progress indicator
@include progress(3);



// To be saved in some other file...
// ---------------------------------

form[disabled] {
    opacity: 0.5;
}

.v-aligned {
    position: relative;
    top: 3px;
}

.info-link {
    color: $info;
    font-weight: bold;
}

.flag-icon {
  height:.75em;
  // margin-right: .15em;//map-get($spacers, 1);
  vertical-align: baseline;
  position: relative;
  top: 1px;
}

.logo {
    width: 300px;
    max-width: 66vw;
}
.logo-bgd {
    display: inline-block;
    background: white;
    padding: 3rem;
    border-radius: 100%;
}

.dropdown-header {
    @include font-size($font-size-base);
    font-weight: bold;

    max-width: 360px - 12px;
    overflow: hidden;
}

.bg-alt-dark {
    background-color: $secondary;
}

.alert-opaque {
  color: white;
  border: none;

  &.alert-primary   { background: $primary; }
  &.alert-secondary { background: $secondary; }
  &.alert-info      { background: $info; }
  &.alert-success   { background: $success; }
  &.alert-warning   { background: $warning; }
  &.alert-danger    { background: $danger; }

  a {
    background: rgba(255,255,255,0.3);
    padding: 0 .25rem;
    color: white;
    &:hover {
      text-decoration: underline;
    }

    /*
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    */
  }
}
