
/* Containers
=================================== */

body, html {
    height: 100%;
    scroll-behavior: smooth;
}

// this allows fluid containers on small screens, and constant containers on larger screens
.area-container {
    width: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
        max-width: map-get($container-max-widths, md);;
    }
    
    @include media-breakpoint-up(lg) {
        max-width: map-get($container-max-widths, lg);;
    }
}

.area {
    padding: $area-padding-y $area-padding-x;
    padding-top: 0;

    &.area-top { // the aera at the top of the page
        padding-top: $area-padding-y;
    }

    @media #{$query-tabs-design-width} {
        padding: $area-padding-y-tabs-design $area-padding-x-tabs-design;
        padding-top: 0;

        &.area-top { // the aera at the top of the page
            padding-top: $area-padding-y-tabs-design;
        }
    }
}

.condensed .area {
    padding: $area-condensed-padding-y $area-condensed-padding-x;
    padding-top: 0;

    &.area-top { // the aera at the top of the page
        padding-top: $area-condensed-padding-y;
    }

    @media #{$query-tabs-design-width} {
        padding: $area-condensed-padding-y-tabs-design $area-condensed-padding-x-tabs-design;
        padding-top: 0;

        &.area-top { // the aera at the top of the page
            padding-top: $area-condensed-padding-y-tabs-design;
        }
    }
}


// ===========================================================================

.pop-ups-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: $z-index-notices;
}


.text-zone {
    margin-bottom: map-get($spacers, 3);
    padding: map-get($spacers, 3);
}


